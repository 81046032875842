<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'activityTypeToken'"
            :value="brandPlace.brandData.activityTypeToken"
            :options="activityTypeTokenOptions"
            v-on:changeValue="activityTypeChanged($event)"
            :title="$t('activityTypes.select')"
            :imgName="'activity-types.svg'"
          />
          <CustomSelectBoxWithImg
            :className="'col-md-6'"
            :id="'brandToken'"
            :value="brandPlace.brandToken"
            :options="brandTokenOptions"
            v-on:changeValue="brandPlace.brandToken = $event"
            :title="$t('brands.select')"
            :imgName="'brand.svg'"
          />

          <CustomInput
            :className="'col-md-12'"
            :id="'fullCode'"
            :value="brandPlace.fullCode"
            v-on:changeValue="brandPlace.fullCode = $event"
            :title="$t('code')"
            :imgName="'code.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'brandPlaceNameAr'"
            :value="brandPlace.brandPlaceNameAr"
            v-on:changeValue="brandPlace.brandPlaceNameAr = $event"
            :title="$t('brandPlaces.nameAr')"
            :imgName="'brand-places.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'brandPlaceNameEn'"
            :value="brandPlace.brandPlaceNameEn"
            v-on:changeValue="brandPlace.brandPlaceNameEn = $event"
            :title="$t('brandPlaces.nameEn')"
            :imgName="'brand-places.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'brandPlaceAddressAr'"
            :value="brandPlace.brandPlaceAddressAr"
            :title="$t('addressAr')"
            :imgName="'address.svg'"
            v-on:changeValue="brandPlace.brandPlaceAddressAr = $event"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'brandPlaceAddressEn'"
            :value="brandPlace.brandPlaceAddressEn"
            :title="$t('addressEn')"
            :imgName="'address.svg'"
            v-on:changeValue="brandPlace.brandPlaceAddressEn = $event"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'brandPlaceLatitude'"
            :value="brandPlace.brandPlaceLatitude"
            :title="$t('latitude')"
            :imgName="'latitude.svg'"
            v-on:changeValue="brandPlace.brandPlaceLatitude = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'brandPlacelongitude'"
            :value="brandPlace.brandPlacelongitude"
            :title="$t('longitude')"
            :imgName="'longitude.svg'"
            v-on:changeValue="brandPlace.brandPlacelongitude = $event"
          />
          <CustomInput
            :className="'col-md-12'"
            :id="'brandPlaceTaxNumber'"
            :value="brandPlace.brandPlaceTaxNumber"
            :title="$t('brandPlaces.taxNumber')"
            :imgName="'number.svg'"
            v-on:changeValue="brandPlace.brandPlaceTaxNumber = $event"
          />

          <TelPicker
            :className="'col-md-3'"
            :value="brandPlace.brandPlacePhone1CCWithCCName"
            v-on:changeCC="brandPlace.brandPlacePhone1CCWithCCName = $event"
          />
          <CustomInput
            :className="'col-md-8'"
            :id="'brandPlacePhone1'"
            :value="brandPlace.brandPlacePhone1"
            :title="$t('phone1')"
            :imgName="'phone.svg'"
            v-on:changeValue="brandPlace.brandPlacePhone1 = $event"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.brandPlacePhone1Status"
            v-on:changeValue="brandPlace.brandPlacePhone1Status = $event"
            :title="$t('activeStatus')"
          />

          <TelPicker
            :className="'col-md-3'"
            :value="brandPlace.brandPlacePhone2CCWithCCName"
            v-on:changeCC="brandPlace.brandPlacePhone2CCWithCCName = $event"
          />
          <CustomInput
            :className="'col-md-8'"
            :id="'brandPlacePhone2'"
            :value="brandPlace.brandPlacePhone2"
            :title="$t('phone2')"
            :imgName="'phone.svg'"
            v-on:changeValue="brandPlace.brandPlacePhone2 = $event"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.brandPlacePhone2Status"
            v-on:changeValue="brandPlace.brandPlacePhone2Status = $event"
            :title="$t('activeStatus')"
          />

          <TelPicker
            :className="'col-md-3'"
            :value="brandPlace.brandPlacePhone3CCWithCCName"
            v-on:changeCC="brandPlace.brandPlacePhone3CCWithCCName = $event"
          />
          <CustomInput
            :className="'col-md-8'"
            :id="'brandPlacePhone3'"
            :value="brandPlace.brandPlacePhone3"
            :title="$t('phone3')"
            :imgName="'phone.svg'"
            v-on:changeValue="brandPlace.brandPlacePhone3 = $event"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.brandPlacePhone3Status"
            v-on:changeValue="brandPlace.brandPlacePhone3Status = $event"
            :title="$t('activeStatus')"
          />

          <TelPicker
            :className="'col-md-3'"
            :value="brandPlace.brandPlacePhone4CCWithCCName"
            v-on:changeCC="brandPlace.brandPlacePhone4CCWithCCName = $event"
          />
          <CustomInput
            :className="'col-md-8'"
            :id="'brandPlacePhone4'"
            :value="brandPlace.brandPlacePhone4"
            :title="$t('phone4')"
            :imgName="'phone.svg'"
            v-on:changeValue="brandPlace.brandPlacePhone4 = $event"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.brandPlacePhone4Status"
            v-on:changeValue="brandPlace.brandPlacePhone4Status = $event"
            :title="$t('activeStatus')"
          />

          <TextArea
            :className="'col-md-11'"
            :id="'mapLink'"
            :value="brandPlace.mapLink"
            v-on:changeValue="brandPlace.mapLink = $event"
            :title="$t('brandPlaces.mapLink')"
            :imgName="'location.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.mapLinkStatus"
            v-on:changeValue="brandPlace.mapLinkStatus = $event"
            :title="$t('activeStatus')"
          />

          <TextArea
            :className="'col-md-11'"
            :id="'faceBookLink'"
            :value="brandPlace.faceBookLink"
            v-on:changeValue="brandPlace.faceBookLink = $event"
            :title="$t('brandPlaces.faceBookLink')"
            :imgName="'facebook.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.faceBookLinkStatus"
            v-on:changeValue="brandPlace.faceBookLinkStatus = $event"
            :title="$t('activeStatus')"
          />

          <TextArea
            :className="'col-md-11'"
            :id="'twitterLink'"
            :value="brandPlace.twitterLink"
            v-on:changeValue="brandPlace.twitterLink = $event"
            :title="$t('brandPlaces.twitterLink')"
            :imgName="'twitter.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.twitterLinkStatus"
            v-on:changeValue="brandPlace.twitterLinkStatus = $event"
            :title="$t('activeStatus')"
          />
          <TextArea
            :className="'col-md-11'"
            :id="'instagramLink'"
            :value="brandPlace.instagramLink"
            v-on:changeValue="brandPlace.instagramLink = $event"
            :title="$t('brandPlaces.instagramLink')"
            :imgName="'instagram.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.instagramLinkStatus"
            v-on:changeValue="brandPlace.instagramLinkStatus = $event"
            :title="$t('activeStatus')"
          />
          <TextArea
            :className="'col-md-11'"
            :id="'webSiteLink'"
            :value="brandPlace.webSiteLink"
            v-on:changeValue="brandPlace.webSiteLink = $event"
            :title="$t('brandPlaces.webSiteLink')"
            :imgName="'link.svg'"
          />
          <CustomCheckbox
            :className="'col-md-1'"
            :value="brandPlace.webSiteLinkStatus"
            v-on:changeValue="brandPlace.webSiteLinkStatus = $event"
            :title="$t('activeStatus')"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'brandPlaceNotes'"
            :value="brandPlace.brandPlaceNotes"
            v-on:changeValue="brandPlace.brandPlaceNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateBrandPlace"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'BrandPlaces' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { BASE_URL } from "@/utils/constants";
import ActivityType from "@/models/activityTypes/ActivityType";
import Brand from "@/models/brands/Brand";
import BrandsFilter from "@/models/brands/BrandsFilter";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { setDataMultiLang } from "@/utils/functions";
import TelPicker from "@/components/general/TelPicker.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBoxWithImg,
    TelPicker,
    CustomCheckbox,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      activityType: new ActivityType(),
      activityTypeTokenOptions: [],
      brand: new Brand(),
      brandsFilter: new BrandsFilter(),
      brandTokenOptions: [],
      brandPlacePriceTypeTokenOptions: [],
    };
  },
  props: ["brandPlace", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.brandPlace.mediaFile = file[0];
      }
    },

    async getDialogOfActivityTypes() {
      this.isLoading = true;
      try {
        this.activityTypeTokenOptions =
          await this.activityType.getDialogOfActivityTypes(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    activityTypeChanged(activityTypeToken) {
      this.brandPlace.brandData.activityTypeToken = activityTypeToken;
      // this.brandPlace.brandToken = "";
      this.brandsFilter.activityTypeToken = activityTypeToken;
      this.getBrandDialog();
    },
    async getBrandDialog() {
      this.isLoading = true;

      try {
        this.brandTokenOptions = await this.brand.getBrandDialog(
          this.language,
          this.userAuthorizeToken,
          this.brandsFilter
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfPriceTypes() {
      this.isLoading = true;
      this.brandPlacePriceTypeTokenOptions = [];
      this.brandPlacePriceTypeTokenOptions.push({
        value: "",
        text: this.$t("selectPriceType"),
      });
      let priceTypes = this.constantsListsData.listPriceType;
      for (let item in priceTypes) {
        this.brandPlacePriceTypeTokenOptions.push({
          value: priceTypes[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            priceTypes[item]["itemNameAr"],
            priceTypes[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async addOrUpdateBrandPlace() {
      this.$emit("addOrUpdateBrandPlace");
    },
  },
  watch: {
    "brandPlace.brandPlacePhone1CCWithCCName": function (val) {
      let cc = val.split(" ");
      this.brandPlace.brandPlacePhone1CountryCode = cc[1];
      this.brandPlace.brandPlacePhone1CountryCodeName = cc[0];
    },
    "brandPlace.brandPlacePhone2CCWithCCName": function (val) {
      let cc = val.split(" ");
      this.brandPlace.brandPlacePhone2CountryCode = cc[1];
      this.brandPlace.brandPlacePhone2CountryCodeName = cc[0];
    },
    "brandPlace.brandPlacePhone3CCWithCCName": function (val) {
      let cc = val.split(" ");
      this.brandPlace.brandPlacePhone3CountryCode = cc[1];
      this.brandPlace.brandPlacePhone3CountryCodeName = cc[0];
    },
    "brandPlace.brandPlacePhone4CCWithCCName": function (val) {
      let cc = val.split(" ");
      this.brandPlace.brandPlacePhone4CountryCode = cc[1];
      this.brandPlace.brandPlacePhone4CountryCodeName = cc[0];
    },
  },
  async created() {
    this.imageSrc = this.brandPlace.brandPlaceImagePath
      ? BASE_URL + this.brandPlace.brandPlaceImagePath
      : this.brandPlace.defaultImg;

    this.getDialogOfActivityTypes();
    this.getBrandDialog();
    this.getDialogOfPriceTypes();
  },
};
</script>

<style lang="scss"></style>
